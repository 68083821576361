import React from "react"
import { graphql, Link } from 'gatsby'
import Background from 'components/background'
import Layout from "templates/BlogPostGet/layout"
import SEO from 'components/seo'
import style from 'templates/BlogPostGet/BlogPostGet.module.scss'
import ReadTime from 'components/readTime'
import Share from 'components/share/'

const NewsReportGet = ({ data, pageContext }) => {
	if (!data.allPressRelease.edges[0]) {
		return (
			<Layout>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className={`${style.nothingHere} cell small-12`}>
							<h1>Nothing to see here</h1>
							<p>The release that you are looking for has either moved or no longer exists.</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
	let release = null;
	if (data.allPressRelease && data.allPressRelease.edges.length) release = data.allPressRelease.edges[0].node;
	const monthNames = ["January", "February", "March", "April", "May", "June",
		"July", "August", "September", "October", "November", "December"
	];
	const nth = function(d) {
		if (d > 3 & d < 21) return 'th';
		switch (d % 10) {
			case 1: return 'st';
			case 2: return 'nd';
			case 3: return 'rd';
			default: return 'th';
		}
	}
	const d = new Date(Date.parse(release.showDate));
	const dateString = `${monthNames[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
	const metaData = data.site.siteMetadata;
	const twitterHandle = metaData.twitterHandle;
	const title = release.headline;
	var schema = {
		"@context": "http://schema.org",
		"@type": "BlogPosting",
		"headline": title,
		"datePublished": new Date(release.showDate).toDateString(),
		"mainEntityOfPage": "True",
		"publisher": {
			"@type": "Organization",
			"name": metaData.title,
		},
		"articleBody": release.body,
	}
	if(release.modified) {
		schema.datemodified = new Date(release.modified).toDateString();
	}
	var SEOimage;
	if(release.headerImage) {
		schema.image = metaData.siteUrl+release.headerImage;
		SEOimage = metaData.siteUrl+release.headerImage;
	}

	return (
		<>
			<Layout image={release.headerImage ? release.headerImage : "/userfiles/News images/2021news_header.jpg"} key="news" link="/news/" goBackText="Back to All News Reports" enableDanosFoundation={false} enableFooterTabs={true} enableReportsFeed={true}>
				<section className={style.intro}>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12 medium-8 medium-offset-2">
								<h1>{title}</h1>
								<div className={style.byline}>
									<ReadTime className={style.readTime} data={release.body} prefix='' suffix='Min Read' />
									<div className={style.separator}>|</div>
									<div className={style.date}>{dateString}</div>
								</div>
								{ release.photo && !release.headerImage && <img src={release.photo} alt={title} style={{ marginBottom: '1rem' }} /> }
							</div>
						</div>
					</div>
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12 medium-2">
								<Share socialConfig={{
									twitterHandle,
									config: {
										url: `${metaData.siteUrl}${release.uri}`,
										title,
									},
								}} />
							</div>
							<div className="cell small-12 medium-8">
								<div className={style.innerContent} dangerouslySetInnerHTML={{__html: (release ? release.body : '')}} />
							</div>
						</div>
					</div>
				</section>
			</Layout>
			<SEO title={title ? title : ''} image={SEOimage} schema={schema} />
		</>
	)
}

export default NewsReportGet;

export const query = graphql`
	query ($uri: String, $lang: String) {
		allPressRelease(filter: {uri: {eq: $uri}, lang: {eq: $lang}}, sort: {fields: showDate, order: DESC}) {
			edges {
				node {
					excerpt
					headline
					body
					photo
					headerImage
					permalink
					showDate
					uri
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
				twitterHandle
				title
				author
			}
		}
	}
`